.whiteMenuIcon {
    color: white;
    padding-left: 10px;
}

.whiteMenuIcon .MuiDataGrid-sortIcon {
    color: white;
}

.whiteMenuIcon .MuiDataGrid-menuIconButton {
    color: white;
}

.centerStyle .MuiDataGrid-columnHeaderTitleContainer {
    color: white;
    justify-content: center;
}

.centerStyle .MuiDataGrid-columnHeader {
    border-right: solid 1px white !important;
}

.centerStyle .MuiDataGrid-sortIcon {
    color: white;
}

.centerStyle .MuiDataGrid-menuIconButton {
    color: white;
}

.sticky-header {
    /* position: sticky;
    left: 0;
    z-index: 1000;
    background-color: #fff; */
    /* padding-left: 10px; */
}

.sticky-cell {
    /* position: sticky;
    left: 0;
    z-index: 1000;
    background-color: #fff; */
    padding-left: 10px;
}

.first-sticky-cell {
    position: sticky !important;
    left: 0 !important;
    z-index: 1000 !important;
    background-color: #fff !important;
    /* opacity: 1 !important; */
    /* padding-left: 10px; */
}