.custom-date-range-picker {
    width: 75%;
    height: 35px;

    .rs-input-group {
        border: solid 1px #8e8e93b0;
        background: #e3e3e3;
        height: 35px !important;

        input {
            background: #e3e3e3;
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .rs-input-group-focus:focus {
        outline: none;
    }
}