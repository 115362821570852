body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#loader-wrapper{
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.loader{
  display: flex;
  justify-content: center;
}

.rs-picker-popup{
  z-index: 1301 !important;
} 

.rs-input-group-inside{
  height: 40px !important;
}

.MuiInputBase-root{
  border-radius: 5px !important;
}

.MuiButtonBase-root{
  border-radius: 5px !important;
}

.MuiAccordionDetails-root{
  /* padding-bottom: 5px !important; */
}

.MuiInputBase-input{
  height: 14px !important;
}

.MuiSelect-select{
  padding-top: 5px !important;
  padding-bottom: 5px !important
}

.rs-picker{
  /* height: 10px !important; */
}

.divider_line {
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin: 1.5rem 0 2rem 0;
  border-radius: 10px;
}

/* .MuiAutocomplete-option {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
} */

.w-100 {
  width: 100% !important;
}